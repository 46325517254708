export default [
     
  {
      header: 'Admin',
    icon: 'SettingsIcon',
    children: [
      // {

      //     title: 'Home',
      //     route: 'dashboard-blank-page',
           
      
      //   },
      //   { 
      //     title: 'Change Password',
      //     route: 'changePassword',
      //     icon: 'LockIcon',
      //   },
      //   { 
      //     title: 'Access Authentication',
      //     route: 'accessAuthentication',
      //     icon: 'ShieldIcon',
      //   },
        { 
          title: 'Set Up',
          route: 'setup',
          icon: 'ToolIcon',
        },
        {
          title: 'Store Holidays',
          route: 'holidays',
          icon: 'CalendarIcon',
        },
        { 
          title: 'Design Tag',
          route: 'designTag',
          icon: 'TagIcon',
        },
        { 
          title: 'Search Invoice',
          route: 'searchInvoice',
          icon: 'SearchIcon',
        },
        // {
        //   title: 'Discount Restriction',
        //   route: 'discountRestriction',
        //   icon: 'PercentIcon',
        // },
        { 
          title: 'SMS Configuration',
          route: 'smsConfiguration',
          icon: 'MessageSquareIcon',
        },
        { 
          title: 'Daily Dashboard',
          route: 'dailyDashboard',
          icon: 'TvIcon',
        },
        // { 
        //   title: 'Payment Gatway Setup',
        //   route: 'paymentGatewaySetup',
        //   icon: 'CastIcon',
        // },
        { 
          title: 'Email Configuration',
          route: 'emailConfiguration',
          icon: 'MailIcon',
        },
        // { 
        //   title: 'Sticker Configuration',
        //   route: 'stickerConfiguration',
        //   icon: 'LayersIcon',
        // },
         
         
        
    ],
  }

]