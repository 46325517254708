export default [

  {
    header: 'Account',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Account Group',
        route: 'accountsGroup',
        icon: 'BookIcon',
      },
      {
        title: 'Account',
        route: 'account',
        icon: 'FilePlusIcon',
      },
      {
        title: 'Vendor',
        icon: 'BookOpenIcon',
        children: [

          {
            title: 'Add Vendor',
            route: 'addVendor',
            icon: 'UserPlusIcon',
          },
          {
            title: 'Vendor Transaction Details',
            route: 'vendorTransactionDetail',
            icon: 'BookIcon',
          },
        ]
      },
      // {

      //   title: 'Cash/Day Book',
      //   route: 'cashDayBook',
      // },
      {
        title: 'Detail Cash/Day Book',
        route: 'detailCashDayBook',
        icon: 'SidebarIcon',
      },
      {
        title: 'Payment',
        route: 'payment',
        icon: 'CreditCardIcon',
      },
      {
        title: 'Receipt',
        route: 'receipt',
        icon: 'PocketIcon',
      },
      // { 
      //   title: 'Payment Type',
      //   route: 'paymentType',

      // },
      // { 
      //   title: 'Tax Report',
      //   route: 'taxReport',

      // },
      {
        title: 'Accounts Receivable',
        route: 'accountReceivable',
        icon: 'FileTextIcon',
      },

    ],
  }

]