export default[
  {
    header: 'Wallet',
    icon:'BriefcaseIcon',
    children:[
      // {
      //   title: 'Currency',
      //   route: 'currencyType',
      //   icon: 'CreditCardIcon',
      // },
      // {
      //   title: 'Transaction Type',
      //   route: 'walletTransactionType',
      //   icon: 'CreditCardIcon',
      // },
      {
        title: 'Wallet List',
        route: 'walletList',
        icon: 'UsersIcon',
      },
      {
        title: 'Transaction Detail',
        route: 'transactionDetail',
        icon: 'CreditCardIcon',
      },
    ]
  },
]