export default [

  {
    header: 'Order',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'New Order',
        route: 'newOrder',
        icon: 'PlusIcon',


      },
      {
        title: 'Order Detail',
        route: 'orderDetail',
        icon: 'EditIcon',

      },
      {
        title: 'Cancel Order',
        route: 'cancelOrder',
        icon: 'DeleteIcon',

      },
      // {
      //   title: 'Delete Order',
      //   route: 'deleteOrder',
      //   icon: 'Trash2Icon'

      // },

    ],
  }

]