export default [

  {
    header: 'Process',
    icon: 'RepeatIcon',
    children: [
      {

        title: 'Print QR Tags',
        route: 'printTag',
        icon: 'TagIcon',
      },
      {
        title: 'Send To Workshop',
        route: 'sendWorkshop',
        icon: 'ShareIcon',
      },
      {
        title: 'Receive From Workshop',
        route: 'receiveWorkshop',
        icon: 'DownloadIcon',
      },
      {
        title: 'Pending for Finishing',
        route: 'pendingFinishing',
        icon: 'WatchIcon',
      },
      {
        title: 'Return To Workshop',
        route: 'returnToWorkShop',
        icon: 'CornerDownLeftIcon',
      },
      {
        title: 'Return To Store',
        route: 'returnToStore',
        icon: 'CornerDownRightIcon',
      },
      // {
      //   title: 'Packing Stickers',
      //   route: 'packingSticker',
      //   icon: 'PackageIcon',
      // },
    ],
  }

]