export default [
     
  {
      header: 'Customer',
    icon: 'UserIcon',
    children: [
      {

          title: 'Search',
          route: 'customerList',
          icon: 'SearchIcon',
      
        },
        // {

        //   title: 'Customer Preferences',
        //   route: 'customerPreference',
        //   icon: 'SlidersIcon',
      
        // },
        {

          title: 'Add New',
          route: 'addNew',
          icon: 'UserPlusIcon',
      
        },
        // {

        //   title: 'Merge',
        //   route: 'merge',
        //   icon: 'GitMergeIcon',
      
        // },
        // {

        //   title: 'Send Promotinal SMS',
        //   route: 'sendSms',
        //   icon: 'MessageSquareIcon',
      
        // },
        
        
    ],
  }

]