export default [

  {
    header: 'Reports',
    icon: 'ClipboardIcon',
    children: [
      {
        title: 'All Customers List',
        route: 'allCustomerList',
        icon: 'UserIcon',
      },
      {
        title: 'Orders',
        route: 'orders',
        icon: 'FileTextIcon',
      },
      {
        title: 'Sales and Delivery',
        route: 'salesDelivery',
        icon: 'TruckIcon',
      },
      {
        title: 'Payment Adjustment',
        route: 'paymentAdjustment',
        icon: 'GitCommitIcon',
      },
      //   {
      //     title: 'Service Wise Order',
      //     route: 'serviceWiseOrder',
      //     icon: 'FileIcon',
      //   },
      //   {
      //     title: 'Service And Garment',
      //     route: 'serviceAndGarment',
      //     icon: 'BriefcaseIcon',
      //   },
      //   {
      //     title: 'Category Wise Report',
      //     route: 'categoryWiseReport',
      //     icon: 'GridIcon',
      //   },
      //   {
      //     title: 'Garment Wise',
      //     route: 'garmentWise',
      //     icon: 'ShoppingBagIcon',
      //   },
      //   {
      //     title: 'Garment Status',
      //     route: 'garmentStatus',
      //     icon: 'CpuIcon',
      //   },
      //   {
      //     title: 'Garment details',
      //     route: 'garmentDetails',
      //     icon: 'AlignCenterIcon',
      //   },

      //   {
      //     title: 'Daily Customer Addition',
      //     route: 'dailyCustomerAddition',
      //     icon: 'UserIcon',
      //   },
      //   {
      //     title: 'Marked Ready by User',
      //     route: 'markedReadyByUser',
      //     icon: 'UserCheckIcon',
      //   },
      //   {
      //     title: 'Without Ticket Delivery',
      //     route: 'withoutTicketDelivery',
      //     icon: 'TrelloIcon',
      //   },
      //   {
      //     title: 'Order by locality',
      //     route: 'orderByLocality',
      //     icon : 'CompassIcon',
      //   },
      //   {
      //     title: 'Pending Stock',
      //     route: 'pendingStock',
      //     icon: 'WatchIcon',
      //   },

    ],
  }

]