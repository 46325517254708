export default [
  {
    header: 'Quick Links',
  },

  {

    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',

  },
  {
    title: 'Dashboard',
    route: 'apollo-dashboard',
    icon: 'TvIcon',
  },


  {
    title: 'Customer',
    icon: 'UserIcon',
    children: [
      {
        title: 'Search',
        route: 'customerList',
        icon: 'SearchIcon',
      },
      // {

      //   title: 'Customer Preferences',
      //   route: 'customerPreference',
      //   icon: 'SlidersIcon',

      // },
      {
        title: 'Add New',
        route: 'addNew',
        icon: 'UserPlusIcon',
      },
      // {
      //   title: 'Merge',
      //   route: 'merge',
      //   icon: 'GitMergeIcon',
      // },
      // {
      //   title: 'Send Promotional SMS',
      //   route: 'sendSms',
      //   icon: 'MessageSquareIcon',
      // },

    ],
  },
  {
    title: 'Wallet',
    icon: 'BriefcaseIcon',
    children: [

      {
        title: 'Wallet List',
        route: 'walletList',
        icon: 'UsersIcon',
      },
      {
        title: 'Transaction Detail',
        route: 'transactionDetail',
        icon: 'CreditCardIcon',
      },
    ]
  },

  {
    title: 'Order',
    icon: 'FileTextIcon',
    children: [
      {

        title: 'New Order',
        route: 'newOrder',
        icon: 'PlusIcon',

      },
      {
        title: 'Order Detail',
        route: 'orderDetail',
        icon: 'EditIcon',

      },
      {
        title: 'Cancel Order',
        route: 'cancelOrder',
        icon: 'DeleteIcon',

      },
      // {
      //   title: 'Delete Order',
      //   route: 'deleteOrder',
      //   icon: 'Trash2Icon'
      // },

    ],
  },


  {
    title: 'Process',
    icon: 'RepeatIcon',
    children: [
      {

        title: 'Print QR Tags',
        route: 'printTag',
        icon: 'TagIcon',
      },
      {
        title: 'Send To Workshop',
        route: 'sendWorkshop',
        icon: 'ShareIcon',
      },
      {
        title: 'Receive From Workshop',
        route: 'receiveWorkshop',
        icon: 'DownloadIcon',
      },
      {
        title: 'Pending for Finishing',
        route: 'pendingFinishing',
        icon: 'WatchIcon',
      },
      {
        title: 'Return To Workshop',
        route: 'returnToWorkShop',
        icon: 'CornerDownLeftIcon',
      },
      {
        title: 'Return To Store',
        route: 'returnToStore',
        icon: 'CornerDownRightIcon',
      },

      // {
      //   title: 'Packing Stickers',
      //   route: 'packingSticker',
      //   icon: 'PackageIcon',
      // },
    ],
  },
  {
    title: 'Account',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Account Group',
        route: 'accountsGroup',
        icon: 'BookIcon',
      },
      {
        title: 'Account',
        route: 'account',
        icon: 'FilePlusIcon',
      },
      {
        title: 'Vendor',
        icon: 'BookOpenIcon',
        children: [

          {
            title: 'Add Vendor',
            route: 'addVendor',
            icon: 'UserPlusIcon',
          },
          {
            title: 'Vendor Transaction Details',
            route: 'vendorTransactionDetail',
            icon: 'BookIcon',
          },
        ]
      },
      // {

      //   title: 'Cash/Day Book',
      //   route: 'cashDayBook',
      //   icon: 'BookIcon',
      // },
      {
        title: 'Detail Cash/Day Book',
        route: 'detailCashDayBook',
        icon: 'SidebarIcon',
      },
      {
        title: 'Payment',
        route: 'payment',
        icon: 'CreditCardIcon',

      },
      {
        title: 'Receipt',
        route: 'receipt',
        icon: 'PocketIcon',
      },
      // {
      //   title: 'Payment Type',
      //   route: 'paymentType',
      //   icon: 'InboxIcon',
      // },
      // {
      //   title: 'Tax Report',
      //   route: 'taxReport',
      //   icon: 'AlignCenterIcon',

      // },
      {
        title: 'Account Receivable',
        route: 'accountReceivable',
        icon: 'FileTextIcon',
      },

    ],
  },

  {
    title: 'Reports',
    icon: 'ClipboardIcon',
    children: [
      {
        title: 'All Customers List',
        route: 'allCustomerList',
        icon: 'UserIcon',
      },
      {
        title: 'Orders',
        route: 'orders',
        icon: 'FileTextIcon',
      },
      {
        title: 'Sales and Delivery',
        route: 'salesDelivery',
        icon: 'TruckIcon',
      },
      {
        title: 'Payment Adjustment',
        route: 'paymentAdjustment',
        icon: 'GitCommitIcon',
      },
      // {
      //   title: 'Service Wise Order',
      //   route: 'serviceWiseOrder',
      //   icon: 'FileIcon',
      // },
      // {
      //   title: 'Service And Garment',
      //   route: 'serviceAndGarment',
      //   icon: 'BriefcaseIcon',
      // },
      // {
      //   title: 'Category Wise Report',
      //   route: 'categoryWiseReport',
      //   icon: 'GridIcon',
      // },
      // {
      //   title: 'Garment Wise',
      //   route: 'garmentWise',
      //   icon: 'ShoppingBagIcon',
      // },
      // {
      //   title: 'Garment Status',
      //   route: 'garmentStatus',
      //   icon: 'CpuIcon',
      // },
      //     {
      //       title: 'Garment details',
      //       route: 'garmentDetails',
      //       icon: 'AlignCenterIcon',
      //     },
      //     
      //     {
      //       title: 'Daily Customer Addition',
      //       route: 'dailyCustomerAddition',
      //       icon: 'UserIcon',
      //     },
      //     {
      //       title: 'Marked Ready by User',
      //       route: 'markedReadyByUser',
      //       icon: 'UserCheckIcon',
      //     },
      //     {
      //       title: 'Without Ticket Delivery',
      //       route: 'withoutTicketDelivery',
      //       icon: 'TrelloIcon',
      //     },
      //     {
      //       title: 'Order by locality',
      //       route: 'orderByLocality',
      //       icon: 'CompassIcon',
      //     },
      //     {
      //       title: 'Pending Stock',
      //       route: 'pendingStock',
      //       icon: 'WatchIcon',
      //     },

    ],
  }
  ,

  {
    title: 'Master Data',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Store Information',
        route: 'storeInformation',
        icon: 'HomeIcon',
      },
      {
        title: 'Workshop Information',
        route: 'workshopInformation',
        icon: 'InfoIcon',
      },
      {
        title: 'Services',
        icon: 'ServerIcon',
        children: [
          {
            title: 'Item Category',
            route: 'itemCategory',
            icon: 'ListIcon',
          },
          {
            title: 'Items',
            route: 'items',
            icon: 'CopyIcon',
          },
          {
            title: 'Services',
            route: 'services',
            icon: 'ServerIcon'
          },
          {
            title: 'Item Service Price',
            route: 'priceList',
            icon: 'ClipboardIcon',
          },
        ]
      },

      {
        title: 'Garment Attributes',
        icon: 'ListIcon',
        children: [
          {
            title: 'Topup Services',
            route: 'topupServices',
            icon: 'BookmarkIcon',
          },
          {
            title: 'Brands',
            route: 'brand',
            icon: 'TagIcon',
          },
          {
            title: 'Defects / Remarks',
            route: 'garmentRemarks',
            icon: 'StarIcon',
          },
          {
            title: 'Colours',
            route: 'colour',
            icon: 'ArchiveIcon',
          },
        ]
      },

      {
        title: 'Garment Return Cause',
        route: 'garmentReturnCause',
        icon: 'RotateCwIcon',
      },
      {
        title: 'Payment Adjustment Type',
        route: 'paymentAdjustmentType',
        icon: 'GitCommitIcon',
      },
      {
        title: 'Constant',
        icon: 'InboxIcon',
        children: [
          {
            title: 'Payment Mode Types',
            route: 'paymentModeType',
            icon: 'InboxIcon',
          },
          // {
          //   title: 'Currency',
          //   route: 'currencyType',
          //   icon: 'CreditCardIcon',
          // },
          {
            title: 'Transaction Type',
            route: 'walletTransactionType',
            icon: 'CreditCardIcon',
          },
        ]
      },

      {
        title: 'Customer Preferences',
        route: 'customerPreference',
        icon: 'SlidersIcon',
      },
      // {
      //   title: 'Communication Preferences',
      //   route: 'communicationPreference',
      //   icon: 'SlidersIcon',
      // },
      {
        title: 'Add Employee',
        route: 'addEmployee',
        icon: 'UsersIcon',
      },
      {
        title: 'EmployeeList',
        route: 'employeeList',
        icon: 'AlignCenterIcon',
      },

    ],
  },

  {
    title: 'Admin',
    icon: 'SettingsIcon',
    children: [
      // {

      //   title: 'Home',
      //   route: 'dashboard-blank-page',


      // },
      // {
      //   title: 'Change Password',
      //   route: 'changePassword',
      //   icon: 'LockIcon',
      // },
      // {
      //   title: 'Access Authentication',
      //   route: 'accessAuthentication',
      //   icon: 'ShieldIcon',
      // },
      {
        title: 'Set Up',
        route: 'setup',
        icon: 'ToolIcon',
      },
      {
        title: 'Store Holidays',
        route: 'holidays',
        icon: 'CalendarIcon',
      },
      {
        title: 'Design Tag',
        route: 'designTag',
        icon: 'TagIcon',
      },
      {
        title: 'Search Invoice',
        route: 'searchInvoice',
        icon: 'SearchIcon',

      },
      // {
      //   title: 'Discount Restriction',
      //   route: 'discountRestriction',
      //   icon: 'PercentIcon',
      // },
      {
        title: 'SMS Configuration',
        route: 'smsConfiguration',
        icon: 'MessageSquareIcon',
      },
      {
        title: 'Daily Dashboard',
        route: 'dailyDashboard',
        icon: 'TvIcon',
      },
      // {
      //   title: 'Payment Gatway Setup',
      //   route: 'paymentGatewaySetup',
      //   icon: 'CastIcon',
      // },
      {
        title: 'Email Configuration',
        route: 'emailConfiguration',
        icon: 'MailIcon',
      },
      // {
      //   title: 'Sticker Configuration',
      //   route: 'stickerConfiguration',
      //   icon: 'LayersIcon',
      // },



    ],
  }


]
