export default [

  {
    header: 'Master Data',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Store Information',
        route: 'storeInformation',
        icon: 'HomeIcon',
      },
      {
        title: 'Workshop Information',
        route: 'workshopInformation',
        icon: 'InfoIcon',
      },
      {
        title: 'Services',
        icon: 'ServerIcon',
        children: [
          {
            title: 'Item Category',
            route: 'itemCategory',
            icon: 'ListIcon',
          },
          {
            title: 'Items',
            route: 'items',
            icon: 'CopyIcon',
          },
          {
            title: 'Services',
            route: 'services',
            icon: 'ServerIcon'
          },
          {
            title: 'Item Service Price',
            route: 'priceList',
            icon: 'ClipboardIcon',
          },
        ]
      },
      {
        title: 'Garment Attributes',
        icon: 'ListIcon',
        children: [
          {
            title: 'Topup Services',
            route: 'topupServices',
            icon: 'BookmarkIcon',
          },
          {
            title: 'Brands',
            route: 'brand',
            icon: 'TagIcon',
          },
          {
            title: 'Defects / Remarks',
            route: 'garmentRemarks',
            icon: 'StarIcon',
          },
          {
            title: 'Colours',
            route: 'colour',
            icon: 'ArchiveIcon',
          },
        ]
      },
      {
        title: 'Garment Return Cause',
        route: 'garmentReturnCause',
        icon: 'RotateCwIcon',
      },
      {
        title: 'Payment Adjustment Type',
        route: 'paymentAdjustmentType',
        icon: 'GitCommitIcon',
      },
      {
        title: 'Constant',
        icon: 'InboxIcon',
        children: [
          {
            title: 'Payment Mode Types',
            route: 'paymentModeType',
            icon: 'InboxIcon',
          },
          // {
          //   title: 'Currency',
          //   route: 'currencyType',
          //   icon: 'CreditCardIcon',
          // },
          {
            title: 'Transaction Type',
            route: 'walletTransactionType',
            icon: 'CreditCardIcon',
          },
        ]
      },
      {
        title: 'Customer Preferences',
        route: 'customerPreference',
        icon: 'SlidersIcon',
      },
      // {
      //   title: 'Communication Preferences',
      //   route: 'communicationPreference',
      //   icon: 'InboxIcon',
      // },
      {
        title: 'Add Employee',
        route: 'addEmployee',
        icon: 'UsersIcon',
      },
      {
        title: 'EmployeeList',
        route: 'employeeList',
        icon: 'AlignCenterIcon',
      },

    ],
  }

]