export default [
     
  {
      header: 'Quick Links',
    icon: 'ListIcon',
    children: [
      {

          title: 'Home',
          route: 'home',
          icon: 'HomeIcon',
      
        },
        {
          title: 'Dashboard',
          route: 'apollo-dashboard',
          icon: 'TvIcon',
        }
    ],
  }

]