<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          <!-- {{userData.fullName}} -->
          <!-- {{ $store.state.userDetails.namePrefix +' '+ $store.state.userDetails.name }} -->
          {{ $store.state.name}}
        </p>
        <!-- <span class="user-status">{{ $store.state.userDetails.roles[0].name }}</span> -->
        <!-- {{ $store.state.roles[0].name}} -->
        {{ roles[0].name }}
        <!-- <span class="user-status">{{userData.role}}</span> -->
      </div>
      <b-avatar
        size="40"
        :src="convertImage(photo)"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!$store.state.name"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item> -->

    <!-- <b-dropdown-divider /> -->

    <b-dropdown-item
      :to="{ name: 'myAccount' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>My Account</span>
    </b-dropdown-item>
    
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import store from '@/store/index'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      //userDetails: this.$store.state.userDetails,
      //userDetails: JSON.parse(localStorage.getItem('userDetails')),
      roles: JSON.parse(localStorage.getItem('roles')),
      photo: localStorage.getItem('photo'),
      avatarText,
    }
  },
  methods: {
    convertImage(profilePic) {
        if (profilePic == null) {
            return require('@/assets/images/avatars/placeholderImage.png');
        } else {
            return 'data:image/*;base64,' + profilePic
        }
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('ability')

      localStorage.removeItem('token')
      localStorage.removeItem('userId')
      localStorage.removeItem('userName')
      localStorage.removeItem('name')
      localStorage.removeItem('photo')
      localStorage.removeItem('isEnebled')
      localStorage.removeItem('empId')
      localStorage.removeItem('storeId')
      localStorage.removeItem('storeName')
      localStorage.removeItem('forcePasswordChange')
      localStorage.removeItem('roles')
      localStorage.removeItem('defaultConfiguration')
      localStorage.removeItem('birthday')
      localStorage.removeItem('anniversary')
      localStorage.removeItem('maxDiscountRestricationFlat')
      localStorage.removeItem('maxDiscountRestricationPersentage')




      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
  created(){
    //alert('ddddd')
    //this.userDetails= JSON.parse(localStorage.getItem('userDetails'));
  }
}
</script>
